.ec-kpi-primary-container {
    min-height: 100vh;
}

/**************************************************************************/
/**************************** CALCULATOR STYLES****************************/
/**************************************************************************/

.ec-kpi-calculator-container {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 40px;
    margin-bottom: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    border: 4px solid #fed072;
    max-width: 1200px;
}

.ec-kpi-calculator-header {
    font-family: 'Playfair Display', serif;
    color: #f15b3d;
    font-size: 5vw;
    text-align: center;
}

.ec-kpi-calculator-subheader {
    font-size: 1.8vw;
    text-align: center;
    font-weight: 500;
    margin-bottom: 30px;
}

.ec-kpi-calculations-container {
    display: flex;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 55px;
}

.ec-kpi-calculations-header-container-desktop {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
}

.ec-kpi-calculations-header-container-mobile {
    display: none;
} 

.ec-kpi-calculations-primary-header-container, .ec-kpi-calculations-symbol-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}



.ec-kpi-calculations-primary-header-container {
    padding-top: 15px;
    padding-bottom: 35px;
}

.ec-kpi-calculations-symbol-container {
    padding-top: 25px;
    padding-bottom: 35px;   
}

.ec-kpi-calculator-equation-header {
    font-size: 2.5vw;
    width: 100%;
    font-family: 'Playfair Display', serif;
    color: #f15b3d;
    text-align: end;
}

.ec-kpi-calculation-symbol {
    font-size: 2.8vw;
}

.ec-kpi-calculations-monthly-revenue-container, .ec-kpi-calculations-sessions-container, .ec-kpi-calculations-aov-container, .ec-kpi-calculations-conversion-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ec-kpi-calculation-subheader {
    text-align: center;
    font-weight: 500;
    font-size: 35px;
    width: 85%;
    margin-right: auto;
    margin-left: auto;
    padding: 0 40px 60px 40px;
}

.ec-kpi-calculation-subheader-results {
    width: 85%;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    font-size: 2vw;
    font-weight: 300;
    margin-top: 0px;
    margin-bottom: 50px;
}

.ec-kpi-results-sentence {
    color: #f15b3d;
    font-weight: 500;
}

.testing {
    display: inline-block;
    position: relative;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.85);
    width: 90%;
    margin-right: auto;
    margin-left: auto;
}

.overlay-content {
    color: white;
    font-family: 'Playfair Display', serif;
    font-size: 4vw;
    font-weight: 400;
    width: 70%;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    padding-top: 4vw;
}

.ec-kpi-calculator-results-container {
    display: flex;
    width: 80%;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-right: auto;
    margin-left: auto;
    justify-content: space-between;
}

.ec-kpi-calculator-stat-container {
    width: 80%;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    padding-top: 15px;
}

.ec-kpi-calculator-results-header {
    font-size: 1.1vw;
    font-weight: 400;
    text-align: center;

}

.ec-kpi-calculator-results-input {
    margin-bottom: 15px;
    width: 100%;
    text-align: center;
    font-size: 2vw;
    font-weight: 300;
    border: none;
    color: #f15b3d;
    border: 3px solid #fed072;
    padding: 0 0 0 0;
}

.ec-kpi-calculator-results-input-blocked {
    margin-bottom: 15px;
    width: 100%;
    text-align: center;
    font-size: 2vw;
    font-weight: 300;
    border: none;
    color: #6a6663;
    border: 3px solid #EFE7E2;
    padding: 0 0 0 0;
    background-color: #EFE7E2;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
}

.ec-kpi-calculator-results-input:focus {
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
}

.ec-kpi-calculator-last-revenue {
    display: flex;
}

.ec-kpi-calculator-inputs-container {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
}

.ec-kpi-calculator-inputs-header {
    font-family: 'Playfair Display', serif;
    color: #f15b3d;
    font-size: 3vw;
    margin-bottom: 30px;
    width: 96%;
    margin-right: auto;
    margin-left: auto;
}

.ec-kpi-calculator-inputs {
    display: flex;
    justify-content: space-between;
}

.ec-kpi-calculator-inputs p {
    margin-bottom: 20px;
}

.ec-kpi-calculator-label {
    margin-right: 30px;
    font-weight: 500;
    font-size: 1.1vw;
    display: inline-block;
    width: 100%;
}

.ec-kpi-calculator-prev-revenue {
    font-weight: 800;
    font-size: 1.1vw;
}

.ec-star-span {
    color: red;
}

.ec-kpi-calculator-input {
    width: 100px;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid lightgrey;
    font-size: 18px;
    text-align: center;
}

.ec-kpi-calculator-impact-container {
    display: flex;
    justify-content: space-between;
    padding-right: 2%;
    padding-left: 2%;
    margin-bottom: 20px 
}

.ec-kpi-calculator-single-impact-container {
    border: 4px solid #fed072;
    width: 225px;
    padding-right: 30px;
    padding-left: 30px;
    transition-duration: 0.5s;
    cursor: pointer;
}

.ec-kpi-calculator-single-impact-container-active {
    background-color: #fed072;
    border: 4px solid #fed072;
    width: 225px;
    padding-right: 30px;
    padding-left: 30px;
    transition-duration: 0.5s;
    cursor: pointer;
}

.ec-kpi-calculator-single-impact-container:hover, .ec-kpi-calculator-single-impact-container-active:hover {
    box-shadow: 0px 0px 13px 4px #BCBCBC;
}

.ec-kpi-calculator-single-impact-container:nth-child(1), .ec-kpi-calculator-single-impact-container-active:nth-child(1) {
    padding-top: 55px;
    padding-bottom:55px;
}

.ec-kpi-calculator-single-impact-container:nth-child(2), .ec-kpi-calculator-single-impact-container-active:nth-child(2) {
    padding-top: 75px;
    padding-bottom: 30px;
}

.ec-kpi-calculator-single-impact-container:nth-child(3), .ec-kpi-calculator-single-impact-container-active:nth-child(3) {
    padding-top: 55px;
    padding-bottom: 30px;
}

.ec-kpi-calculator-single-impact-header {
    text-align: center;
}

.ec-kpi-calculator-single-impact-header:nth-child(2) {
    margin-bottom: 15px;
}

.ec-kpi-calculator-single-impact-text {
    font-size: 0.8em;
    font-style: italic;
    color: grey;
    text-align: center;
}

/**************************************************************************/
/*********************************FORM STYLS*******************************/
/**************************************************************************/

.ec-kpi-form-container {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 15px;
    max-width: 1200px;
}

.ec-kpi-form-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-right: auto;
    margin-left: auto;
}

.ec-kpi-form-inputs-container {
    margin-right: auto;
    margin-left: auto;
}

.ec-kpi-form-inputs-container p {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 600;
    width: 33.3%;
    min-width: 200px;
    margin-bottom: 30px;
}

.ec-kpi-form-label {
    margin-bottom: 15px;
}

.ec-kpi-form-input {
    font-size: 18px;
    border: 1px solid lightgrey;
    height: 35px;
    width: 80%;
}

.ec-kpi-form-submit-container {
    margin-right: auto;
    margin-left: auto;
}

.ec-kpi-form-submit-button {
    display: block;
    background-color: black;
    font-weight: 700;
    font-size: 15px;
    border: none;
    color: white;
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
    padding: 12px 30px;
    cursor: pointer;
}

.ec-kpi-form-error-container {
    margin-top: 15px;
}

.ec-kpi-form-error-message {
    text-align: center;
    color: #f15b3d;
    font-size: 12px;
}

/**************************************************************************/
/*****************************QUALITATIVE STYLS****************************/
/**************************************************************************/

.ec-kpi-qualitative-container {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 30px;
    max-width: 1200px;
}

.ec-kpi-qualitative-header {
    font-family: 'Playfair Display', serif;
    color: #f15b3d;
    font-size: 3vw;
    margin-bottom: 30px;
}

.ec-kpi-qualitative-single-metric-header {
    font-family: 'Playfair Display', serif;
    color: #f15b3d;
    font-size: 1.9vw;
    margin-bottom: 10px;
}

.ec-kpi-qualitative-single-metric-text {
    font-size: 1.1vw;
    font-weight: 500;
    margin-bottom: 15px;
}

.ec-kpi-qualitative-single-metric-textarea {
    width: 100%;
}

/**************************************************************************/
/*******************************MEDIA QUERIES******************************/
/**************************************************************************/

@media(max-width: 1024px) {
    .ec-kpi-calculator-impact-container {
        padding-right: 0;
        padding-left: 0;
    }

    .ec-kpi-calculator-impact-container {
        display: flex;
        justify-content: space-between;
        padding-right: 2%;
        padding-left: 2%;
        margin-bottom: 20px 
    }
    
    .ec-kpi-calculator-single-impact-container {
        border: 3px solid #fed072;
        width: 29%;
        padding-right: 30px;
        padding-left: 30px;
        transition-duration: 0.5s;
        cursor: pointer;
    }
    
    .ec-kpi-calculator-single-impact-container-active {
        background-color: #fed072;
        border: 3px solid #fed072;
        width: 29%;
        padding-right: 30px;
        padding-left: 30px;
        transition-duration: 0.5s;
        cursor: pointer;
    }
    
    .ec-kpi-calculator-single-impact-header {
        text-align: center;
        font-size: 1.5vw;
    }
    
    .ec-kpi-calculator-single-impact-text {
        font-size: 1.2vw;
        font-style: italic;
        color: grey;
        text-align: center;
    }
}

@media(max-width: 768px) {
    .ec-kpi-calculator-header {
        font-size: 7vw;
    }

    .ec-kpi-calculator-subheader {
        font-size: 3vw;
    }

    .ec-kpi-calculation-subheader {
        font-size: 3vw;
    }

    .ec-kpi-qualitative-container {
        padding-bottom: 0%;
    }

    .ec-kpi-qualitative-header {
        font-size: 5.5vw;
        margin-right: auto;
        margin-left: auto;
        width: 70%;
        text-align: center;
    }

    .ec-kpi-qualitative-single-metric-header {
        text-align: center;
    }

    .ec-kpi-qualitative-single-metric-header {
        font-size: 4vw;
        margin-bottom: 15px;
    }

    .ec-kpi-qualitative-single-metric-text {
        margin-bottom: 25px;
    }

    .ec-kpi-qualitative-single-metric{
        margin-bottom: 40px;
    }

    .ec-kpi-form-label {
        font-size: 1.7vw;
    }

    .ec-kpi-form-inputs-container p {
        min-width: 140px;
    }

    .ec-kpi-form-input {
        font-size: 1.7vw;
        height: 25px;
        width: 70%;
    }

    .ec-kpi-calculator-impact-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px; 
        padding-right: 0;
        padding-left: 0;
    }
    
    .ec-kpi-calculator-single-impact-container {
        border: 3px solid #fed072;
        width: 29%;
        padding-right: 15px;
        padding-left: 15px;
        transition-duration: 0.5s;
        cursor: pointer;
    }
    
    .ec-kpi-calculator-single-impact-container-active {
        background-color: #fed072;
        border: 3px solid #fed072;
        width: 29%;
        padding-right: 15px;
        padding-left: 15px;
        transition-duration: 0.5s;
        cursor: pointer;
    }

    .ec-kpi-calculator-single-impact-container:nth-child(2), .ec-kpi-calculator-single-impact-container-active:nth-child(2) {
        padding-top: 65px;
        padding-bottom: 30px;
    }
    
    .ec-kpi-calculator-single-impact-header {
        text-align: center;
        font-size: 1.5vw;
    }
    
    .ec-kpi-calculator-single-impact-text {
        font-size: 1.2vw;
        font-style: italic;
        color: grey;
        text-align: center;
    }
}

@media(max-width: 550px) {
    .ec-kpi-calculator-impact-container {
        flex-direction: column;
        width: 100%;
    }

    .ec-kpi-calculator-single-impact-container-active, .ec-kpi-calculator-single-impact-container {
        width: 100%;
    }

    .ec-kpi-calculator-single-impact-container-active:nth-child(1), .ec-kpi-calculator-single-impact-container:nth-child(1) {
        padding: 10px 0;
        margin-bottom: 10px;
    }

    .ec-kpi-calculator-single-impact-container-active:nth-child(2), .ec-kpi-calculator-single-impact-container:nth-child(2) {
        padding: 10px 0;
        margin-bottom: 10px;
    }

    .ec-kpi-calculator-single-impact-container-active:nth-child(3), .ec-kpi-calculator-single-impact-container:nth-child(3) {
        padding: 10px 0;
    }

    .ec-kpi-calculator-single-impact-header:nth-child(2) {
        margin-bottom: 5px;
    }
}

@media(max-width: 480px) {

    .ec-kpi-calculator-container {
        width: 95%;
    }

    .ec-kpi-calculator-header {
        font-size: 10vw;
    }

    .ec-kpi-calculator-subheader {
        font-size: 5vw;
    }

    .ec-kpi-calculation-subheader {
        font-size: 5vw;
    }

    .ec-kpi-calculator-submission-line {
        height: 2px;
        margin-top: 10px;
        margin-bottom: 5px;
        background-color: black;
    }

    .ec-kpi-calculations-container {
        flex-direction: column;
    }

    .ec-kpi-calculations-primary-header-container {
        width: 100%;
        flex-direction: row;
        margin-right: auto;
        margin-left: auto;
        justify-content: space-between;
        padding: 0;
    }

    .ec-kpi-calculations-header-container-desktop {
        display: none;
    } 

    .ec-kpi-calculations-header-container-mobile {
        display: block;
        font-weight: 600;
    } 

    .ec-kpi-calculator-stat-container {
        width: 40%;
        padding-top: 5px;
    }

    .ec-kpi-calculations-monthly-revenue-container, .ec-kpi-calculations-sessions-container, .ec-kpi-calculations-aov-container, .ec-kpi-calculations-conversion-container, .ec-kpi-calculations-primary-header-container, .ec-kpi-calculations-symbol-container {
        flex-direction: row;
        position: relative;
    }

    .ec-kpi-calculator-equation-header {
        text-align: center;
        font-size: 7vw;
    }

    .ec-kpi-calculations-symbol-container {
        padding: 0 23%;
    }

    .ec-kpi-calculator-results-header {
        font-size: 3vw;
        padding-bottom: 10px;
        padding-top: 5px;
        font-weight: 500;
    }

    .ec-kpi-calculator-results-input, .ec-kpi-calculator-results-input-blocked {
        padding: 5px 0;
        margin-bottom: 0;
        font-size: 6vw;
    }

    .ec-kpi-calculation-symbol {
        font-size: 5vw;
    }

    .ec-kpi-qualitative-header {
        font-size: 9vw;
        margin-right: auto;
        margin-left: auto;
        width: 90%;
        text-align: center;
    }

    .ec-kpi-qualitative-single-metric-header {
        font-size: 7vw;
    }

    .ec-kpi-qualitative-single-metric-text, .ec-kpi-qualitative-single-metric-textarea {
        font-size: 4vw;
    }

    .ec-kpi-qualitative-single-metric{
        margin-bottom: 40px;
    }

    .ec-kpi-form-div {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .ec-kpi-form-div p {
        margin-right: auto;
        margin-left: auto;
        width: 100%;
        margin: 0;
    }

    .ec-kpi-calculation-subheader-results {
        font-size: 4vw;
        margin-bottom: 20px;
    }

    .ec-kpi-form-input {
        width: 100%;
        height: auto;
        font-size: 4vw;
        padding-top: 1%;
        padding-bottom: 1%;
        margin-bottom: 15px;
    }

    .ec-kpi-form-label {
        font-size: 5vw;
        font-weight: 400;
        margin-bottom: 10px;
    }

    .ec-kpi-calculator-inputs-header {
        font-size: 8vw;
        text-align: center;
    }

    .ec-kpi-calculator-single-impact-container-active:nth-child(1), .ec-kpi-calculator-single-impact-container:nth-child(1) {
        padding: 10px;
        height: 100%;
    }

    .ec-kpi-calculator-single-impact-container-active:nth-child(2), .ec-kpi-calculator-single-impact-container:nth-child(2) {
        padding: 10px;
        height: 100%;
    }

    .ec-kpi-calculator-single-impact-container-active:nth-child(3), .ec-kpi-calculator-single-impact-container:nth-child(3) {
        padding: 10px 25px;
        height: 100%;
    }

    .ec-kpi-calculator-single-impact-text {
        font-size: 3vw;
    }

    .ec-kpi-calculator-single-impact-header {
        font-size: 5vw;
    }

    .ec-kpi-calculator-inputs {
        flex-direction: column;
        font-size: 5vw;
    }

    .ec-kpi-calculator-inputs-left, .ec-kpi-calculator-inputs-right {
        width: 100%;
    }

    .ec-kpi-calculator-label {
        font-size: 5vw;
        margin-bottom: 10px;
    }

    .ec-kpi-calculator-prev-revenue {
        font-size: 5vw;
    }

    .ec-kpi-calculator-last-revenue {
        flex-direction: column;
    }
}

