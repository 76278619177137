.ec-kpi-cp-signup-container {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 40px 13%;
    border: 4px solid #fed072;
    max-width: 1200px;
}

.ec-kpi-cp-signup-left, .ec-kpi-cp-signup-right {
    width: 40%;
}

.ec-kpi-cp-signup-left {
    padding-top: 70px;
}

.ec-kpi-cp-signup-header {
    font-family: 'Playfair Display', serif;
    color: #f15b3d;
    font-size: 3vw;
    margin-bottom: 15px;
    text-align: center;
}

.ec-kpi-cp-logo-container {
    margin-right: auto;
    margin-left: auto;
}

.ec-kpi-cp-signup-cp-logo {
    width: 200px;
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.ec-kpi-cp-signup-text {
    text-align: center;
}

.ec-kpi-cp-signup-button {
    display: block;
    background-color: black;
    font-weight: 700;
    font-size: 15px;
    border: none;
    color: white;
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
    padding: 8px 45px;
    cursor: pointer;
}

/**************************************************************************/
/*******************************MEDIA QUERIES******************************/
/**************************************************************************/

@media(max-width: 768px) {
    .ec-kpi-cp-signup-container {
        margin-right: auto;
        margin-left: auto;
        padding: 40px 50px;
    }

    .ec-kpi-cp-signup-cp-logo {
        width: 100%;
        display: block;
    }

    .ec-kpi-cp-signup-left {
        padding-top: 40px
    }

    .ec-kpi-cp-signup-header {
        font-size: 4vw;
    }
}

@media(max-width: 681px) {
    .ec-kpi-cp-signup-left {
        padding-top: 35px;
    }

    .ec-kpi-cp-signup-text {
        font-size: 2vw;
    }

    .ec-kpi-cp-signup-header {
        font-size: 4vw;
    }

    .ec-kpi-cp-signup-button {
        font-size: 2.5vw;
        padding: 8px 30px;
    }
}

@media(max-width: 480px) {
    .ec-kpi-cp-signup-container {
        display: flex;
        flex-direction: column;
        padding-top: 0;
    }

    .ec-kpi-cp-logo-container {
        width: 75%;
        margin-bottom: 15px;
    }

    .ec-kpi-cp-signup-left, .ec-kpi-cp-signup-right {
        width: 100%;
    }

    .ec-kpi-cp-signup-header {
        font-size: 7vw;
    }

    .ec-kpi-cp-signup-text {
        font-size: 4vw;
    }

    .ec-kpi-cp-signup-button {
        font-size: 3.5vw;
        padding: 8px 30px;
    }
}