.ec-kpi-footer {
    display: flex;
    flex-direction: row-reverse;
    bottom: 0;
    background-color: #f15b3d;
    height: 40px;
    width: 100%;
    padding: 12px 30px 0 0;
    font-size: 13px;
}

.ec-kpi-footer-arrow {
    color: white;
    font-weight: 400;
}