.ec-kpi-header {
    background-color: #f15b3d;
    padding: 25px;
}

.ec-kpi-header-header {
    font-family: 'Playfair Display', serif;
    color: white;
    font-size: 5vw;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
}

.ec-kpi-header-logo-container {
    display: flex;
    justify-content: space-between;

}

.ec-kpi-header-link, .ec-kpi-header-link-cp {
    width: 20%;
}

.ec-kpi-header-link-cp {
    margin-top: -15px;
}

.ec-kpi-header-logo {
    width: 100%;
}

.ec-kpi-header-subheader {
    text-align: center;
    font-size: 1.8vw;
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 600;
}

@media(max-width: 768px) {
    .ec-kpi-header-header {
        font-size: 7vw;
    }

    .ec-kpi-header-subheader {
        font-size: 3vw;
    }

    .ec-kpi-header-logo-container {
        margin-bottom: 20px;
    }

    .ec-kpi-header-link, .ec-kpi-header-link-cp {
        width: 35%;
    }

    .ec-kpi-header-link-cp {
        margin-top: -10px;
    }
}

@media(max-width: 480px) {
    .ec-kpi-header-header {
        font-size: 10vw;
    }

    .ec-kpi-header-subheader {
        font-size: 5vw;
    }
}

